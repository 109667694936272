import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField, CustomEventLegacyFieldType, CustomEventLegacyFieldValue, DropdownItem } from '@models/custom-event-field';
import { User } from '@models/user';

@Component({
  selector: 'app-select-multiple-resources',
  templateUrl: './select-multiple-resources.component.html',
  styleUrls: ['./select-multiple-resources.component.scss'],
})
export class SelectMultipleResourcesComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() isReadOnly: boolean;
  @Input() isLoading: boolean;

  @Input() type: string;

  @Input() placeholder: string;
  @Input() items: DropdownItem[];
  @Input() parentTaskAssignees: User[] = [];
  @Input() multiple: boolean = true;

  @ViewChild('dropdown', { static: false, read: ElementRef }) dropdown!: ElementRef;

  displayDropdown: boolean = false;
  selectedOption: any[] = [];
  tagField: string  = CustomEventLegacyFieldValue.TAGS.toLowerCase()

  constructor(
    private renderer: Renderer2,
  ) {
    this.renderer.listen('window', 'click', (event: Event) => {
    if (this.dropdown && !this.dropdown.nativeElement.contains(event.target)) {
      this.displayDropdown = false;
    }
  }); 
}

  ngOnInit() {
    this.displayDropdown = false;
    if (this.parentFormGroup.value[this.controlName]) {
      for(let value of this.parentFormGroup.value[this.controlName]) {
        this.selectedOption.push(value)
      }
    }
    this.items.forEach(item => {
      item.isSelected = this.selectedOption.includes(item);
    });
  }

  toggleDropdown() {
    this.displayDropdown = !this.displayDropdown
  }

  selectedOptions(event, item) {
    if (event.target.checked) {
      this.selectedOption.push(item); // Add checked option
    } else {
      this.selectedOption = this.selectedOption.filter(
        (selectedItem) => selectedItem !== item
      ); // Remove unchecked option
    } 
    if (this.controlName === 'location'){
      this.parentFormGroup.value.location = this.selectedOption;
    } else {
      this.parentFormGroup.value[this.controlName] = this.selectedOption;
    }
  }

  selectedTags(item) {
    if (this.selectedOption.includes(item)) {
      this.selectedOption = this.selectedOption.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      this.selectedOption.push(item)
    }
    this.parentFormGroup.value[this.controlName] = this.selectedOption;
  }

  hideDropdown() {
    this.displayDropdown = false;
  }


  removeOption(option) {
    this.selectedOption.splice(this.selectedOption.indexOf(option), 1)
    const itemToUncheck = this.items.find(item => item === option);
    if (itemToUncheck) {
      itemToUncheck.isSelected = false;
    }
  }

}
