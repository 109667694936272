import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { UrlGiverService } from '../url-giver.service';
import { Logger } from '../logger';
import { SessionService } from '@services/session.service';
import { HttpStatus } from '@constants/http/http-status';
import { NGXLogger } from 'ngx-logger';

/**
 * HTTP request handler for all authorization purposes.
 * Responsible for ensuring authorization headers sanity, and for managing access token refreshes
 * and resending requests with updated tokens afterwards.
 */
export class AuthorizationHandler {
  constructor(
    private request: HttpRequest<any>,
    private next: HttpHandler,
    private authService: AuthService,
    private urlGiverService: UrlGiverService,
    private sessionService: SessionService,
    private logger: NGXLogger
    ) {}

  public handle(): Observable<any> {
    // All requests to token endpoint should be authorized using basic authentication
    if (this.request.url.startsWith(this.urlGiverService.giveAPIUrl()) && this.request.url.endsWith(AuthService.AUTH_TOKEN_URL_PART)) {
      return this.handleTokenEndpointRequests();
    }

    // All other requests to the API should be authenticated using an access_token when available)
    else if (this.request.url.startsWith(this.urlGiverService.giveAPIUrl()) && this.authService.isAuthenticated()) {
      return this.handleAPIRequestsWhenAuthenticated();
    }

    // All other requests should pass through as-is (this includes API requests when an access_token is *not* available)
    else {
      return this.next.handle(this.request);
    }
  }

  /**
   * Handle all requests for token retrieval and renewal on token-specific endpoint.
   * These should be authenticated with basic authentication.
   */
  private handleTokenEndpointRequests(): Observable<any> {
    const authenticatedRequest = this.getRequestWithBasicAuthorization();
    return this.next.handle(authenticatedRequest).pipe(catchError(error => {
      // A 400 will be thrown if request_token is invalid
      // A 401 will be thrown if basic authentication is invalid
      // Log out the user in both cases
      if ((error.status === HttpStatus.BAD_REQUEST || error.status === HttpStatus.UNAUTHORIZED) && this.sessionService.getSession()) {
        this.logger.info('400 or 401 while trying to authenticate against token endpoint. Logging out...');
        this.authService.redirectTologout();
      }

      // Rethrow all errors in any case
      throw error;
    }));
  }

  private getRequestWithBasicAuthorization() {
    return this.request.clone({ setHeaders: { 'Authorization': `Basic ${btoa('angular:secret')}` }});
  }

  /**
   * Handle all requests to API endpoints.
   * These should be authenticated with access tokens. If a request is refused due to invalid access tokens (401),
   * the request should be resent after access tokens have been renewed using refresh token (if available).
   */
  private handleAPIRequestsWhenAuthenticated(): Observable<any> {
    // If an access_token refresh is already in progress, wait for it to complete before sending
    if (this.authService.isTokenRefreshInProgress()) {
      return this.waitForNewAccessTokenBeforeSending();
    }

    // Otherwise, send immediately using current access_token
    else {
      const authenticatedRequest = this.getRequestWithTokenAuthorization(this.authService.getAccessToken());
      // if the request is from 2FA verification handle request normally
      if (authenticatedRequest.url === this.urlGiverService.giveSubmitOtpAPIUrl()) {
        return this.next.handle(authenticatedRequest);
      }
      else {
        return this.next.handle(authenticatedRequest).pipe(catchError(error => {
          // We should only try to refresh access_token when receiving a 401 upon sending an authenticated request
          if (error.status === HttpStatus.UNAUTHORIZED) {
            return this.refreshAccessTokenAndResend();
          }
  
          // Rethrow all errors that are *not* 401
          else {
            throw error;
          }
        }));
      }
    }
  }

  private waitForNewAccessTokenBeforeSending(): Observable<any> {
    Logger.info(`Token refresh already in progress, waiting for new access token to be available before sending...`);
    this.logger.info(`Token refresh already in progress, waiting for new access token to be available before sending...`);
    return this.internalSendRequestWhenRefreshDone(this.authService.waitForNewAccessToken());
  }

  private refreshAccessTokenAndResend(): Observable<any> {
    Logger.info(`Access token expired, try to refresh token and resend...`);
    this.logger.info(`Access token expired, try to refresh token and resend...`);
    return this.internalSendRequestWhenRefreshDone(this.authService.internalRefreshUserSession());
  }

  private internalSendRequestWhenRefreshDone(inputPipe: Observable<any>): Observable<any> {
    return inputPipe.pipe(
      switchMap(accessToken => {
        if (accessToken === AuthService.NO_TOKEN_MUTEX_VALUE) {
          this.logger.info('Not retrying the request as access token refresh was unsuccessful.');
          return of(null);
        } else {
          Logger.info(`Token refresh successful, sending request with new access token ${accessToken}`);
          this.logger.info(`Token refresh successful, sending request with new access token ${accessToken}`);
          const authenticatedRequest = this.getRequestWithTokenAuthorization(accessToken);
          return this.next.handle(authenticatedRequest);
        }
      }),
      catchError(error => {
        if (error.status === HttpStatus.UNAUTHORIZED && this.sessionService.getSession()) {
          // Log out user if access_token still invalid after refresh
          this.logger.error('access_token still invalid after refresh', error);
          this.authService.redirectTologout();
        }

        // Rethrow all errors in any case
        throw error;
      }));
  }

  private getRequestWithTokenAuthorization(accessToken: string) {
    return this.request.clone({ setHeaders: { 'Authorization': `Bearer ${accessToken}` }});
  }
}
