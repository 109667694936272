<ng-template [ngIf]="!deviceService.isMobile">
  <div #eventFormWebapp *ngIf="currentEventId" [hidden]="!currentEventId" class="popup-background">
    <div class="container">
      <div class="popup-close-button">
        <ion-icon name="custom-close"></ion-icon>
      </div>
      <app-event-form-webapp 
        #eventContainer
        [clickOnForm]="clickOnForm"
        [itemIdToDuplicate]="eventIdToDuplicate"
        [taskToLink]="idTaskToLink">
      </app-event-form-webapp>
    </div>


  </div>

  <app-event-list-webapp
  [taskToFilter]="idTaskToFilter"></app-event-list-webapp>
</ng-template>

<ng-template [ngIf]="deviceService.isMobile">
  <app-event-preview-mobile [itemId]="currentEventId" *ngIf="currentEventId && currentEventId !=='new' && !isStartEdit" [isFaultyEvent]="isFaultyEvent"
    (editForm)="displayEditForm($event)"></app-event-preview-mobile>

  <app-event-form-mobile *ngIf="currentEventId ==='new' || isStartEdit" [itemId]="currentEventId"
    [itemIdToDuplicate]="eventIdToDuplicate" [taskToLink]="idTaskToLink" [isFaultyEvent]="isFaultyEvent" (editForm)="displayEditForm($event)">
  </app-event-form-mobile>
  <app-event-list *ngIf="!currentEventId" [taskToFilter]="idTaskToFilter"></app-event-list>
</ng-template>
