import { Component, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { DeviceService } from '../../../services/device.service';
import { ComponentCanDeactivate } from '../../../services/guards/pending-changes.guard';
import { IntercomService } from '../../../services/intercom.service';
import { EventFormMobileComponent } from './event-form/event-form-mobile.component';
import { EventFormWebappComponent } from './event-form/event-form-webapp.component';
import { EventListWebappComponent } from './event-list/event-list-webapp.component';
import { EventListComponent } from './event-list/event-list.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass']
})
export class EventComponent implements ComponentCanDeactivate {


  public currentEventId: string | null;
  public eventIdToDuplicate: string | null;
  public idTaskToLink: string | null;
  public idTaskToFilter: string | null;

  public isFaultyEvent: boolean = false;
  @Output() isClickOnForm: EventEmitter<any> = new EventEmitter();

  public isStartEdit = false;
  @ViewChild(EventFormWebappComponent) eventFormWebappComponent;
  @ViewChild(EventFormMobileComponent) eventFormMobileComponent;
  @ViewChild(EventListComponent) eventListComponent;
  @ViewChild(EventListWebappComponent) eventListWebappComponent;
  @ViewChild('eventContainer', { static: false, read: ElementRef }) eventContainer!: ElementRef;
  @ViewChild('eventFormWebapp', { static: false, read: ElementRef }) eventFormWebapp!: ElementRef;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public deviceService: DeviceService,
    private intercomService: IntercomService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute
  ) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.eventFormWebapp?.nativeElement.contains(event.target)){
        if (this.eventContainer?.nativeElement.contains(event.target)) {
        } else {
          this.router.navigate(['..'], { relativeTo: this.activatedRoute });
        }
      }
    });
    // Track action in intercom
    this.intercomService.trackUserAction(IntercomUserActions.LaunchDiaryModule);
    route.paramMap.pipe(
      withLatestFrom(route.fragment)
    ).subscribe(([paramMap, fragment]) => {
      if (paramMap.get('eventId')) {
        this.currentEventId = paramMap.get('eventId');
        this.handleRouteFragment(fragment);
      } else if (paramMap.get('faultyEventId')) {
        this.currentEventId = paramMap.get('faultyEventId');
        this.handleRouteFragment(fragment);
        this.isFaultyEvent = true;
      } else {
        this.currentEventId = null;
        if (fragment) {
          this.handleRouteFragment(fragment);
        }
      }
    });
  }

  private handleRouteFragment(fragment: string | null) {
    if (fragment && fragment.split(':').length > 1) {
      const type = fragment.split(':')[0];
      if (type === 'task') {
        this.idTaskToLink = fragment.split(':')[1];
      } else if(type === 'task-filter') {
        this.idTaskToFilter = fragment.split(':')[1];
      } else {
        this.eventIdToDuplicate = fragment.split(':')[1];
      }
      this.router.navigate([]);
    } else {
      this.eventIdToDuplicate = null;
      this.idTaskToLink = null;
      this.idTaskToFilter = null;
    }
  }

  canDeactivate(): (boolean | Observable<boolean>) {
    return !this.currentEventId || (
      (!this.eventFormWebappComponent || this.eventFormWebappComponent.canDeactivate())
      && (!this.eventFormMobileComponent || this.eventFormMobileComponent.canDeactivate()));
  }

  displayEditForm(event: boolean): void {
    this.isStartEdit = event;
  }
}
