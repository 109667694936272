<!-- WEBAPP TEMPLATE -->
<div *ngIf="!deviceService.isMobile" class="site-diary-events-list-webapp">

  <!-- APP UPDATE BANNER -->
  <app-update-banner-web></app-update-banner-web>

  <div class="row top-row m-0 w-100">
    <div class="flex-row ml-0" *ngIf="status.length !== 0">
      <div *ngFor="let item of status" >
        <div class="status pr-3" *ngIf="item.id !== EventStatus.PENDING">
          <ion-icon [color]="item.color" name="bubble" class="pr-1"></ion-icon>
          <ion-label>
            {{ item.name | translate }}
          </ion-label>
        </div>
      </div>
    </div>

    <!-- SEARCHBAR -->
    <div class="col-4 d-flex flex-row w-50 m-0 p-0">
      <ion-searchbar #searchInput class="pl-0 no-shadow"
        placeholder="{{'filter.search.placeholder.message' | translate}}" animated (keyup.enter)="applyAllFilters()"></ion-searchbar>
    </div>
    <!-- END OF SEARCH BAR-->
    <div class="top-btn-grp">
      <button *ngIf="canCreateEvent" type="button" class="btn btn-primary m-0 ml-1" (click)="createEvent()">
        +&nbsp;{{'events.action.create' | translate}}
      </button>
      <div *ngIf="userRightsService.hasRight(userRightsService.USER_RIGHTS.site.event.export)" ngbDropdown class="d-inline-block m-0 ml-2">
        <button class="btn white-background-color text-dark export-btn" [disabled]="maximumReportsDownloading()" id="exportReportDropdown" ngbDropdownToggle> 
          {{'btn.export' | translate}}
          <div class="maximum-exports">Export upto 3 reports at once</div>
        </button>
        <div ngbDropdownMenu aria-labelledby="exportReportDropdown">
          <!-- PDF -->
          <button
            ngbDropdownItem
            (click)="openPdfReportModal()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)">
            <span>
              {{ 'events.export.pdf.label' | translate }}
            </span>
          </button>
          <!-- EXCEL -->
          <button
            ngbDropdownItem
            (click)="getXlsReport()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)">
            <span>
              {{ 'events.export.excel.label' | translate }}
            </span>
          </button>
          <!-- SUMMARY PDF -->
          <button
            ngbDropdownItem
            (click)="openPdfSummaryModal()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)" >
            <span>
              {{ 'events.export.pdf.summary.label' | translate }}
            </span>
          </button>
          <!-- CSV -->
          <button
            ngbDropdownItem
            (click)="getCsvReport()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)">
            <span>
              {{ 'events.export.csv.label' | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>

  </div>

  <!-- FILTERS -->
  <div *ngIf="eventsFilters" class="flex-row filters">
    <div class="filters">
      <!-- DATE FROM -->
      <div class="filter" (click)="openDatePicker('startDatetime')">
        <div class="input-group">
          <input id="pickerDateFrom" class="form-control"
              placeholder="{{ 'filter.from' | translate }}" [ngModel]="eventsFilters.dateFrom | date : localizedFormat"
                 [ngClass]="{'is-invalid': invalidDatesInFilter()}">
          <div class="input-group-append">
            <button class="btn btn-outline-primary override-outline" type="button">
              <em></em>
            </button>
          </div>
        </div>
      </div>
      <!-- DATE TO -->
      <div class="filter" (click)="openDatePicker('endDatetime')">
        <div class="input-group">
          <input id="pickerDateTo" class="form-control"
              placeholder="{{ 'filter.to' | translate }}" [ngModel]="eventsFilters.dateTo  | date : localizedFormat"
                 [ngClass]="{'is-invalid': invalidDatesInFilter()}">
          <div class="input-group-append">
            <button class="btn btn-outline-primary override-outline" type="button">
              <em></em>
            </button>
          </div>
        </div>
      </div>
      <!-- CONTRACTOR -->
      <div class="filter">
        <angular2-multiselect class="contractors" [data]="contractors" [settings]="multiselectContractorSettings" (onOpen)="swapSearchBar()"
          id="selectedContractor" [(ngModel)]="selectedContractorItems">
        </angular2-multiselect>
      </div>
      <!-- TAG -->
      <div class="filter">
        <angular2-multiselect class="tags" [data]="tags" [settings]="multiselectTagSettings" (onOpen)="swapSearchBar()"
          id="selectedTag" [(ngModel)]="selectedTagItems">
        </angular2-multiselect>
      </div>
      <!-- LOCALISATION -->
      <div class="filter">
        <angular2-multiselect class="locations" [data]="locations" [settings]="multiselectLocationSettings" (onOpen)="swapSearchBar()"
          id="selectedLocation" [(ngModel)]="selectedLocationItem">
        </angular2-multiselect>
      </div>
      <!-- CREATOR -->
      <div class="filter">
        <angular2-multiselect class="creators" [data]="creators" [settings]="multiselectCreatorSettings" (onOpen)="swapSearchBar()"
          id="selectedCreator" [(ngModel)]="selectedCreatorItems">
        </angular2-multiselect>
      </div>
      <!-- STATUS -->
      <div class="filter">
        <angular2-multiselect class="states" [data]="status" [settings]="multiselectStatusSettings" (onOpen)="swapSearchBar()"
          id="selectedStatus" [(ngModel)]="selectedStatusItems">
        </angular2-multiselect>
      </div>
      <!-- TASK -->
      <div class="filter">
        <angular2-multiselect class="tasks" [data]="tasks" [settings]="multiselectTaskSettings" (onOpen)="swapSearchBar()"
          id="selectedTask" [(ngModel)]="selectedTaskItems">
        </angular2-multiselect>
      </div>
      <!-- APPLY FILTER BUTTON -->
      <div class="filter">
        <button class="btn apply-filter-btn text-dark" (click) = "applyAllFilters()" [disabled]="invalidDatesInFilter() || getFilterValidationErrorText().length > 0">
          {{'btn.apply_filters' | translate}}
        </button>
      </div>
      <!-- CLEAR FILTER BUTTON -->
      <div class="filter">
        <button class="btn btn-outline-primary clear-all-btn" (click) = "clearAllFilters()" >
         {{'btn.clear_all' | translate}}
        </button>
      </div>
      <!-- REFRESH BUTTON  -->
      <div class="ml-auto">
        <div class="force-refresh-button">
          <button class="btn btn-link" (click)="forceRefresh()">
            <ion-icon name="refresh" mode="ios"></ion-icon>
            <span>{{'label.refresh' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <div>
      <small class="text-danger" *ngIf="invalidDatesInFilter()">{{'form.error.event-and-task.negativeDuration' | translate }}</small>
      <small class="text-danger" *ngFor="let error of getFilterValidationErrorText()" >
        <div>
          {{error | translate}}
        </div>
      </small>
    </div>
  </div>
  <!-- END FILTERS -->

  <!-- FAULTY EVENTS INDICATOR CARD -->
  <ion-card *ngIf="hasFaultyItems()" class="danger">
    <ion-card-header (click)="routeToFaultyItems()">
      <ion-card-subtitle *ngIf = "numberOfFaultyItems === 1">{{numberOfFaultyItems}} {{'faulty.items.one.sync.error.title' | translate }}</ion-card-subtitle>
      <ion-card-subtitle *ngIf = "numberOfFaultyItems > 1">{{numberOfFaultyItems}} {{'faulty.items.sync.error.title' | translate }}</ion-card-subtitle>
      <i class="fa-angle-right fas"></i>
    </ion-card-header>
  </ion-card>

  <!-- DATATABLE -->
  <div *ngIf="showCompleteEvents" class="row">
    <div class="col-12 col-sm-12 col-md-12 mt-1 btn-fixed-space">
      <ngx-datatable #table class="bootstrap" [ngClass]="{ 'not-scrollable': !canScrollDatatable }"
        [limit]="itemPerPage" [columnMode]="'force'" [messages]="{emptyMessage: 'events.list.no_event' | translate}"
        [rows]="events" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="60" [rowHeight]="'auto'"
        [sorts]="[{prop: 'start_dateTime', dir: 'desc'}]" (activate)="onActivateRow($event)">

        <ngx-datatable-column prop="status" name="{{'events.list.state' | translate}}" [minWidth]="60" [maxWidth]="60"
          cellClass="cell-status">
          <ng-template ngx-datatable-cell-template let-value="value">
            <ion-icon [color]="getStatus(value).color" name="bubble" class="pl-1"
              matTooltip="{{ getStatus(value).name | translate}}"></ion-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="startDatetime" name="{{'events.list.occurred_on' | translate}}" [minWidth]="80">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="font-weight-normal fs-1">{{ value | date:'mediumDate' }}</span>
            <br />
            <span class="font-weight-normal fs-1">{{ value | date:'shortTime' }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="title" name="{{'events.list.title' | translate}}" [minWidth]="200" [maxWidth]="500"
          [width]="300" [comparator]="customCompareString">
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-event="row" let-value="value">
            <ion-grid fixed>
              <ion-row no-padding>
                <ion-col no-padding class="big" text-start size="12">
                  <span class="font-weight-normal fs-1" *ngIf="event.id !== ''">
                    {{ value ? event.title : 'label.no_title' | translate }}
                  </span>
                </ion-col>
              </ion-row>
              <ion-row class="mt-1" no-padding>
                <ion-col no-padding size="12">
                  <ion-badge *ngFor="let tag of event.tags" class="tag-badge" margin-end color="primary">{{ tag.name }}</ion-badge>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="contractors" name="{{'label.contractors' | translate}}" [minWidth]="150">
          <ng-template ngx-datatable-cell-template let-contractors="value">
            <span *ngFor="let contractor of contractors; let i = index" class="font-weight-normal fs-1">
              {{contractor.name}}
              <span *ngIf="i < contractors.length - 1">, </span>
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="locationObject" name="{{'events.list.location' | translate}}" [minWidth]="150"
          [comparator]="customCompareString">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="font-weight-normal fs-1">{{value.name}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="false" prop="attachments" name="{{'events.list.images' | translate}}"
          [minWidth]="60">
          <ng-template ngx-datatable-cell-template let-event="row" let-value="value">
            <div class="gallery-cell">
              <ion-item lines="none" *ngIf="value?.length > 0">
                <img
                  [src]="getThumbnailUrl(value[0], event.id) | secureImageSrc | async"
                  class="thumbnail small mr-2">
                <ion-label color="medium" *ngIf="value?.length > 1">+{{value.length - 1}}</ion-label>
              </ion-item>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="createdBy" name="{{'events.list.created_by' | translate}}" [minWidth]="150"
          [comparator]="customCompareAuthor">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span
              class="font-weight-normal fs-1">{{ value.id ? value.name : 'users.error.not_found' | translate}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <app-datatable-footer class="w-100 h-25" [dataLoading]="false"
              (changeNbItemEvent)="receiveItemPerPage($event)" [totalEntries]="rowCount" [isWebAppFilteredTableFooter]="true"
              [table]="table" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount"></app-datatable-footer>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
  <!-- END DATATABLE -->

  <!-- FILTERED EVENTS DATATABLE -->
  <div *ngIf="!showCompleteEvents" class="row">
    <div class="col-12 col-sm-12 col-md-12 mt-1 btn-fixed-space">
      <div class="custom-paginator">
        <div *ngIf="dataLoading" class="datatable-spinner">
          <span>{{'loading_text.loading' | translate}}</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <ngx-datatable #table class="bootstrap" [ngClass]="{ 'not-scrollable': !canScrollDatatable }"
        [limit]="itemPerPage" [columnMode]="'force'" [messages]="{emptyMessage: 'events.list.no_event' | translate}"
        [rows]="filteredEvents" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="60" [rowHeight]="'auto'"
        [sorts]="[{prop: 'start_dateTime', dir: 'desc'}]" (activate)="onActivateFilteredEventRow($event)">

        <ngx-datatable-column prop="status" name="{{'events.list.state' | translate}}" [minWidth]="60" [maxWidth]="60"
          cellClass="cell-status">
          <ng-template ngx-datatable-cell-template let-value="value">
            <ion-icon [color]="getStatus(value).color" name="bubble" class="pl-1"
              matTooltip="{{ getStatus(value).name | translate}}"></ion-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="startDatetime" name="{{'events.list.occurred_on' | translate}}" [minWidth]="80">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="font-weight-normal fs-1">{{ value | date:'mediumDate' }}</span>
            <br />
            <span class="font-weight-normal fs-1">{{ value | date:'shortTime' }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="title" name="{{'events.list.title' | translate}}" [minWidth]="200" [maxWidth]="500"
          [width]="300" [comparator]="customCompareString">
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-event="row" let-value="value">
            <ion-grid fixed>
              <ion-row no-padding>
                <ion-col no-padding class="big" text-start size="12">
                  <span class="font-weight-normal fs-1" *ngIf="event.id !== ''">
                    {{ value ? event.title : 'label.no_title' | translate }}
                  </span>
                </ion-col>
              </ion-row>
              <ion-row class="mt-1" no-padding>
                <ion-col no-padding size="12">
                  <ion-badge *ngFor="let tag of event.tags" class="tag-badge" margin-end color="primary">{{ tag.name }}</ion-badge>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="contractors" name="{{'label.contractors' | translate}}" [minWidth]="150">
          <ng-template ngx-datatable-cell-template let-event="row">
            <span *ngFor="let contractor of event.contractors; let i = index" class="font-weight-normal fs-1">
              {{ contractor.name }}
              <span *ngIf="i < event.contractors.length - 1">, </span>
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="locationObject" name="{{'events.list.location' | translate}}" [minWidth]="150"
          [comparator]="customCompareString">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="font-weight-normal fs-1">{{value.name}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="createdBy" name="{{'events.list.created_by' | translate}}" [minWidth]="150"
          [comparator]="customCompareString">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span
              class="font-weight-normal fs-1">{{ value ? value : 'users.error.not_found' | translate}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <app-datatable-footer class="w-100 h-25" [dataLoading]="false"
              (changeNbItemEvent)="receiveItemPerPage($event)" [totalEntries]="rowCount" [isWebAppFilteredTableFooter]="true"
              [table]="table" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount"></app-datatable-footer>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
  <!-- END FILTERED EVENTS DATATABLE -->
</div>
<!-- END WEBAPP TEMPLATE -->

