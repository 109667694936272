import { CustomEventFormSection } from "./custom-event-form-layout";
import { ModelElement } from "./model-element";

export enum CustomEventFieldTypeValue {
  STRING = 'String',
  NUMBER = 'Number',
  LEGACY = 'Legacy',
  SHORT_TEXT = 'Short Text',
  LONG_TEXT = 'Long Text',
  DATE = 'Date',
  DROPDOWN ='Dropdown',
  CURRENCY= 'Currency',
  TIME = 'Time',
  MULTIPLE_CHOICE = 'Multiple choice',
  CHECKBOX = 'Checkbox',
  NUMERICAL_SCALE = 'Numerical Scale'
}

export enum MandatoryFieldDateTypes {
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
}

export enum CustomDateFormats {
  DMY = <any>"custom_event_form.field_type.date.dmy",
  MDY = <any>"custom_event_form.field_type.date.mdy",
  YMD = <any>"custom_event_form.field_type.date.ymd"
}

export enum CustomCurrencies {
  AED = 'AED',
  AUD = 'AUD',
  CAD = 'CAD',
  EUR = 'EUR',
  NZD = 'NZD',
  GBP = 'GBP',
  USD = 'USD',
  ZAR = 'ZAR'
}

export class CustomEventFieldType {
  name: string;
  value: CustomEventFieldTypeValue;
}

export interface ExportReportType {
  title: string;
  id: string;
}

export class CustomEventLegacyFieldType {
  name: string;
  value: CustomEventLegacyFieldValue;
  active: boolean;
  isVisible: boolean;
}

export interface DropdownItem {
  isSelected: boolean;
}

export enum CustomEventLegacyFieldValue {
  CONTRACTORS = 'CONTRACTOR',
  LOCATION = 'LOCATION',
  PHOTOS = 'PHOTOS',
  TAGS = 'TAGS',
  PEOPLE = 'PEOPLE',
  EQUIPMENT = 'EQUIPMENT',
  MATERIALS = 'MATERIALS',
  NOTES = 'NOTES'
}

export enum CustomEventLegacyFieldTitle {
  CONTRACTOR = "Contractor",
  LOCATION = "Location",
  PHOTOS = "Add photos",
  TAGS = "Tags",
  PEOPLE = "People",
  EQUIPMENT = "Equipment",
  MATERIALS = "Materials",
  NOTES = "Note/Comment",
}

export const allFormSectionTypes: CustomEventFormSection[] = [
  {
    sectionTitle: "Activities information",
    sectionFields: ["CONTRACTOR", "LOCATION", "PHOTOS", "TAGS"],
    isVisible: true,
  },
  {
    sectionTitle: "Resources",
    sectionFields: ["PEOPLE", "EQUIPMENT", "MATERIALS"],
    isVisible: true,
  },
  {
    sectionTitle: "Notes",
    sectionFields: ["NOTES"],
    isVisible: true,
  },
];

export const allLegacyFieldTypes: CustomEventLegacyFieldType[] = [
  { name: 'custom_event_form.legacy_field.contractor', value: CustomEventLegacyFieldValue.CONTRACTORS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.location', value: CustomEventLegacyFieldValue.LOCATION, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.photos', value: CustomEventLegacyFieldValue.PHOTOS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.tags', value: CustomEventLegacyFieldValue.TAGS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.people', value: CustomEventLegacyFieldValue.PEOPLE, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.equipment', value: CustomEventLegacyFieldValue.EQUIPMENT, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.materials', value: CustomEventLegacyFieldValue.MATERIALS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.notes', value: CustomEventLegacyFieldValue.NOTES, active: true, isVisible: true }
]

export class CustomEventField {
  id: string;
  title: string;
  fieldType: CustomEventFieldType;
  isVisible: boolean;
  legacyFieldValue?: CustomEventLegacyFieldType;
  children?: {
    [key: string]: any
  }

  public static CUSTOMEVENTFIELDTYPE: CustomEventFieldType[] = [
    { name: 'custom_event_form.field_type.string', value: CustomEventFieldTypeValue.STRING },
    { name: 'custom_event_form.field_type.number', value: CustomEventFieldTypeValue.NUMBER },
    { name: 'custom_event_form.field_type.legacy', value: CustomEventFieldTypeValue.LEGACY },
    { name: 'custom_event_form.field_type.short_text', value: CustomEventFieldTypeValue.SHORT_TEXT },
    { name: 'custom_event_form.field_type.long_text', value: CustomEventFieldTypeValue.LONG_TEXT },
    { name: 'custom_event_form.field_type.date', value: CustomEventFieldTypeValue.DATE },
    { name: 'custom_event_form.field_type.dropdown', value: CustomEventFieldTypeValue.DROPDOWN },
    { name: 'custom_event_form.field_type.currency', value: CustomEventFieldTypeValue.CURRENCY },
    { name: 'custom_event_form.field_type.time', value: CustomEventFieldTypeValue.TIME },
    { name: 'custom_event_form.field_type.multiple_choice', value: CustomEventFieldTypeValue.MULTIPLE_CHOICE },
    { name: 'custom_event_form.field_type.checkbox', value: CustomEventFieldTypeValue.CHECKBOX },
    { name: 'custom_event_form.field_type.numerical_scale', value: CustomEventFieldTypeValue.NUMERICAL_SCALE }
  ]

  constructor (
    id: string = ModelElement.generateId(),
    title: string = '',
    fieldType: CustomEventFieldType = CustomEventField.CUSTOMEVENTFIELDTYPE[0],
    isVisible: boolean = true,
    legacyFieldValue = null,
    children: any = {}
  ) {
    this.id = id;
    this.title = title;
    this.fieldType = fieldType;
    this.isVisible = isVisible;
    this.legacyFieldValue = legacyFieldValue;
    this.children = children;
  }
}