<div class="selected-option">
  <div [ngClass]="controlName === tagField ? 'tag-color' : 'selected-element-background'" *ngFor="let option of selectedOption">
    <span *ngIf="controlName !== tagField" class="option-name">{{ option.name }}</span>
    <ion-badge *ngIf="controlName === tagField" class="tag-badge">{{ option.name }}</ion-badge>
    <button *ngIf="!isReadOnly" [ngClass]="controlName === tagField ? 'tag-close-button' : 'close-button'" ion-button icon-only (click)="removeOption(option)">
      <ion-icon name="custom-close"></ion-icon>
    </button>
  </div>
</div>
<div #dropdown class="readonly-dropdown" *ngIf="!isReadOnly">
  <div class="input" (click)="toggleDropdown()">
    {{ placeholder }}
  </div>
  <div *ngIf="displayDropdown && (items.length !== 0)">
    <div *ngIf="displayDropdown && controlName !== tagField" class="dropdown">
      <div class="list-options" *ngFor="let item of items; index as i">
        <input type="checkbox" id="option_{{i}}" [(ngModel)]="item.isSelected" (change)="selectedOptions($event, item)">
        <label class="label" for="option_{{i}}">{{ item.name }}</label>
      </div>
    </div>
    <div *ngIf="displayDropdown && controlName === tagField" class="dropdown tag-dropdown">
      <div class="list-options tag-options" *ngFor="let item of items; index as i" (click)="selectedTags(item)">
        <div>
          <ion-badge class="tag-badge tag-padding"  [ngClass]="{'tag-not-selected' : !selectedOption.includes(item)}">{{ item.name }}</ion-badge>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="displayDropdown && (items.length === 0)">
    <span class="empty-dropdown">{{ 'events.no_items' | translate }}</span>
  </div>
</div>
