<div *ngIf="customField && isEdited && !isMobileEventPreviewComponent" class="custom-field" [ngClass]="{'custom-field-mobile': deviceIsMobile}">
  <div [formGroup]="parentFormGroup">
    <div formArrayName="customFields">
      <div *ngFor="let customFieldForm of customFields.controls">
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.NUMBER" [formGroup]="customFieldForm">
          <label [ngClass]="deviceIsMobile ? 'mobile-label': 'custom-form-field-title'">
            {{customField.title}}
          </label>
          <input [type]="inputType" placeholder="{{ placeholderText | translate}}" formControlName="value"
            min="0" step="0.00001" oninput="validity.valid || (value='');"
            [ngClass]="{'number-field': inputType === customFieldType.NUMBER, 'custom-form-field-content': !deviceIsMobile, 'mobile-number-field': deviceIsMobile}">
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.STRING" [formGroup]="customFieldForm">
          <label [ngClass]="deviceIsMobile ? 'mobile-label': 'custom-form-field-title'">
            {{customField.title}}
          </label>
          <ion-textarea auto-grow = "true" (keydown.enter)="$event.stopPropagation()" id="description" formControlName="value"
            [readOnly]="!isEdited" placeholder="{{ placeholderText | translate }}" [ngClass]="{'custom-form-field-content web-customtextarea': !deviceIsMobile, 'is-edited' : inputType === customFieldType.STRING, 'mobile-text-area' : deviceIsMobile}"></ion-textarea>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.SHORT_TEXT" [formGroup]="customFieldForm">
          <app-custom-form-short-field [inputType]="inputType" [customField]="customField" [deviceIsMobile]="deviceIsMobile" [placeholderText]="placeholderText" [isEdited]="isEdited" [parentFormGroup]="customFieldForm">
          </app-custom-form-short-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.LONG_TEXT" [formGroup]="customFieldForm">
          <app-custom-form-long-text [inputType]="inputType" [customField]="customField" [deviceIsMobile]="deviceIsMobile" [placeholderText]="placeholderText" [isEdited]="isEdited" [parentFormGroup]="customFieldForm">
        </app-custom-form-long-text>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.DATE" [formGroup]="customFieldForm">
          <app-custom-form-date-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile" [dateFormat]="dateFormat"></app-custom-form-date-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.DROPDOWN" [formGroup]="customFieldForm">
          <app-custom-form-dropdown-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile"></app-custom-form-dropdown-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.CURRENCY" [formGroup]="customFieldForm">
          <app-custom-form-currency-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile"></app-custom-form-currency-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.TIME" [formGroup]="customFieldForm" [dateFormat]="dateFormat">
          <app-custom-form-time-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile"></app-custom-form-time-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.MULTIPLE_CHOICE" [formGroup]="customFieldForm" [dateFormat]="dateFormat">
          <app-custom-form-multiple-choice-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile"></app-custom-form-multiple-choice-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.CHECKBOX" [formGroup]="customFieldForm" [dateFormat]="dateFormat">
          <app-custom-form-checkbox-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile"></app-custom-form-checkbox-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType === customFieldType.NUMERICAL_SCALE" [formGroup]="customFieldForm" [dateFormat]="dateFormat">
          <app-custom-form-numerical-scale-field [isEdited]="isEdited" [customField]="customField" [parentFormGroup]="customFieldForm" [deviceIsMobile]="deviceIsMobile"></app-custom-form-numerical-scale-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="customField && !isEdited && !isMobileEventPreviewComponent" class="custom-field" [ngClass]="{'custom-field-mobile': deviceIsMobile}">
  <div [formGroup]="parentFormGroup">
    <div formArrayName="customFields">
      <div *ngFor="let customFieldForm of customFields.controls">
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && !customFieldHasDifferentPreview(customField)" [formGroup]="customFieldForm"
          [ngClass]="{'custom-form-field-container': !deviceIsMobile}" class="custom-event-preview-webapp">
          <label [ngClass]="{'custom-form-field-title': !deviceIsMobile}" class="mb-0">
            {{customField.title}}
          </label>
          <div [ngClass]="{'custom-form-field-content': !deviceIsMobile}" appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea custom-field-value" disabled>
            {{ getCustomFieldValue(customFieldForm) }}
          </div>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && customField.fieldType.value === customFieldType.DATE" [formGroup]="customFieldForm"
          [ngClass]="{'custom-form-field-container': !deviceIsMobile}" class="custom-event-preview-webapp">
          <app-custom-form-date-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-date-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && customField.fieldType.value === customFieldType.DROPDOWN" [formGroup]="customFieldForm"
          [ngClass]="{'custom-form-field-container dropdown-container': !deviceIsMobile}" class="dropdown-field-preview">
          <app-custom-form-dropdown-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-dropdown-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && customField.fieldType.value === customFieldType.CURRENCY" [formGroup]="customFieldForm"
          [ngClass]="{'custom-form-field-container': !deviceIsMobile}" class="custom-event-preview-webapp">
          <app-custom-form-currency-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-currency-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && customField.fieldType.value === customFieldType.TIME" [formGroup]="customFieldForm"
          class="custom-event-preview-webapp">
          <app-custom-form-time-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-time-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && customField.fieldType.value === customFieldType.MULTIPLE_CHOICE" [formGroup]="customFieldForm"
          class="custom-event-preview-webapp">
          <app-custom-form-multiple-choice-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-multiple-choice-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && !isCheckboxArrayEmpty(customFieldForm) && customField.fieldType.value === customFieldType.CHECKBOX" [formGroup]="customFieldForm"
          class="custom-event-preview-webapp">
          <app-custom-form-checkbox-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-checkbox-field>
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm) && customField.fieldType.value === customFieldType.NUMERICAL_SCALE" [formGroup]="customFieldForm">
          <app-custom-form-numerical-scale-field [customField]="customField" [parentFormGroup]="customFieldForm" [isEdited]="isEdited"></app-custom-form-numerical-scale-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent && getCustomFieldValueFromEvent(customField)" class="custom-event-preview-mobile">
  <div>
    <ion-row>
      <ion-col>
        <label class="label">
          {{ customField.title }}
        </label>
      </ion-col>
    </ion-row>
    <ion-row class="custom-field-value" >
      <ion-col>
        <p>
          <textarea appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea" disabled>{{ customField.fieldType.value === customFieldType.DATE? (getCustomFieldValueFromEvent(customField) | date: localisedFormat ) : getCustomFieldValueFromEvent(customField) }}</textarea>
        </p>
      </ion-col>
    </ion-row>
  </div>
</div>
