import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField } from '@models/custom-event-field';

@Component({
  selector: 'app-custom-form-time-field',
  templateUrl: './custom-form-time-field.component.html',
  styleUrls: ['./custom-form-time-field.component.scss'],
})
export class CustomFormTimeFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  constructor() { }

  ngOnInit() {}

  inputFill(): boolean {
    const timeInput = document.getElementById('time') as HTMLInputElement;
    if (timeInput.value !== "") {
      return true
    }
      return false
  }
}
